export const helpIds = {
  layoutPanel: '',
};

export const TestIds = {
  baseAvatar: 'base-avatar',
  layoutPanel: {
    root: 'layoutPanelRoot',
    circleSizeSlider: 'layoutCircleSizeSlider',
  },
};

export const Selectors = {
  wrapper: '.avatarWrapper',
  root: '.root',
  avatarContent: '.root::content',
  avatarAnonymousSvg: '.root::anonymous',
};

export const CssVarPrefix = {
  globalPrefix: '--',
  scopedPrefix: '--WutAvatar-',
};

export const CssVars = {
  circleSize: 'circleSize',
};

export const translationKeys = {
  componentName: 'dsgnsys_compDisplayName_WutAvatar',
  layoutPanel: {
    title: 'dsgnsys_WutAvatar_panelTitle_layout',
    sizeSliderLabel: 'dsgnsys_WutAvatar_layout_sizeSliderLabel',
  },
  stylablePanel: {
    avatar: 'dsgnsys_WutAvatar_design_avatar_dropdown_label',
    text: 'dsgnsys_WutAvatar_design_text_dropdown_label',
    fill: 'StylablePanel_Design_Borders_Fill_Tooltip',
    background: 'StylablePanel_Design_Fills_Tab_Title',
    corners: 'StylablePanel_Design_Corners_Tab_Title',
    borders: 'StylablePanel_Design_Borders_Tab_Title',
    shadows: 'StylablePanel_Design_Shadows_Tab_Title',
    avatarSkin: 'StylablePanel_Design_Button_Icon_Tab_Label',
    textSection: 'dsgnsys_WutAvatar_design_text_dropdown_label',
  },
};
