import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
    width: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
