
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuColorPickerProps,
  IWbuColorPickerMapperProps,
  IWbuColorPickerControllerProps,
  IWbuColorPickerStateValues,
} from '../../../components/WbuColorPicker/WbuColorPicker.props';

export default withCompController<
  IWbuColorPickerMapperProps,
  IWbuColorPickerControllerProps,
  IWbuColorPickerProps,
  IWbuColorPickerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
