import * as React from 'react';
import {
  Thumbnails,
  Composites,
  TextLabel,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';
import type { ManagedOptionDataType } from '@wix/editor-elements-types/leanComponents';
import { defaultIcon } from '@wix/lean-components-infra/dist/runtime/leanPanels/Components/Assets/manageOptions_defaultIcon';
import { Constants } from '../constants';
import type { IWbuThumbnailsProps } from '../WbuThumbnails.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuThumbnails.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuThumbnails: React.FC<IWbuThumbnailsProps> = props => {
  const {
    id,
    className,
    fieldTitle,
    tooltipInfo,
    setNumberOfColumns,
    thumbnailHeight,
    displayLabels,
    options,
    showEmptyThumbnail,
    divider,
    updateComponentPropsInViewer,
    onChange,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const getIcon = (item: ManagedOptionDataType) => {
    if (item.illustration) {
      return item.illustration.svgId ===
        `<svg data-svg-id="fallback-defaultSvg" />`
        ? defaultIcon()
        : item.illustration.svgId;
    }
    return defaultIcon();
  };

  const enrichedOptions = options.map((item: ManagedOptionDataType) => {
    const newItem: ManagedOptionDataType = {
      ...item,
      label: displayLabels ? item.label : undefined,
    };
    const __html = getIcon(newItem);
    return {
      ...newItem,
      skin: 'transparent',
      illustration: <div dangerouslySetInnerHTML={{ __html }} />,
    };
  });

  const _onChange = (selectedOptionValue: string) => {
    const optionsWithNewSelectedValue = options.map(option => {
      return {
        ...option,
        isDefault: option.value === selectedOptionValue,
      };
    });
    updateComponentPropsInViewer({ options: optionsWithNewSelectedValue });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const selectedOption = enrichedOptions.find(option => option.isDefault);

  const optionValue =
    selectedOption?.value || (showEmptyThumbnail && Constants.noneValue) || '';

  const getNoneThumbnailProps = () =>
    showEmptyThumbnail
      ? {
          value: Constants.noneValue,
          label: displayLabels ? Constants.NoneLabel : '',
        }
      : undefined;

  // We add global style this way because `global.scss` files are not properly loaded for
  // this component.
  const Style = () => {
    const css = `
    .composite-with-info-icon > .has-tooltip.info-icon-tooltip {
      top: 24px;
    }`;
    return <style dangerouslySetInnerHTML={{ __html: css }} />;
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Composites.Thumbnails>
        <Style />
        <TextLabel value={fieldTitle} />
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        <Thumbnails
          className={Constants.ThumbnailsClassName}
          options={enrichedOptions}
          onChange={_onChange}
          value={optionValue}
          noneThumbnailProps={getNoneThumbnailProps()}
          maxThumbsPerRow={setNumberOfColumns}
          fixedRatio={thumbnailHeight === Constants.fixedRatio}
        />
      </Composites.Thumbnails>
    </WbuContainer>
  );
};

export default WbuThumbnails;
