import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import { isNoneComposition } from '../utils/WutBadgeUtils';

const props = withCompInfo<any, any>()(
  ['compData', 'compStylableClass'],
  ({ compData, compStylableClass }) => {
    return {
      ...{ alt: '', composition: 'TextAndIcon', icon: {}, text: 'My Badge' },
      ...compData,
      stylableClassName: compStylableClass,
    };
  },
);

const css = withCompInfo<any, any>()(
  ['styleProperties', 'compData'],
  ({ styleProperties, compData }) => {
    const { spacing = 8, iconPosition = 'row' } = styleProperties;
    const { composition } = compData;
    const rootWidthHeight = isNoneComposition(composition) ? '100%' : 'auto';
    const wrapperWidthHeight = isNoneComposition(composition)
      ? 'unset'
      : 'auto !important';

    return {
      ...(!isNoneComposition(composition) && {
        width: 'auto !important',
        height: 'auto !important',
      }),
      '--spacing': spacing,
      '--icon-position': iconPosition,
      '--root-width': rootWidthHeight,
      '--root-height': rootWidthHeight,
      '--wrapper-width': wrapperWidthHeight,
      '--wrapper-height': wrapperWidthHeight,
    };
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, css, stateRefs });
