
import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';


const props = withCompInfo<any, any>()(
  ['compData'],
  ({compData}) => {
      
    return {
      ...{ divider: 'long',
suffixIcon: {},
prefixIcon: {},
weight: 'normal',
skin: 'standard',
size: 'medium',
url: '',
text: 'Text Button',
 },
...compData,

    };
  },
);




export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
