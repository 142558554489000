import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import type { IWbuSliderProps } from '../WbuSlider.props';

export const props = withCompInfo<IWbuSliderProps, any>()(
  ['compData'],
  ({ compData }) => ({
    ...compData,
  }),
);

export const sdkData = withCompInfo<
  { migrationExperiment: boolean | string },
  any
>()(['experiments'], ({ experiments }) => {
  const {
    'specs.thunderbolt.panelbuilder_velo_migration': migrationExperiment,
  } = experiments;
  return {
    migrationExperiment:
      typeof migrationExperiment === 'string'
        ? Boolean(migrationExperiment) && migrationExperiment === 'true'
        : Boolean(migrationExperiment),
  };
});

export default createComponentMapperModel({
  props,
  sdkData,
});
