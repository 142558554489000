import * as React from 'react';

import { ColumnControllerControl } from '@wix/wix-base-ui/next';
import './WbuColumnControllerControl.global.scss';

import type { IWbuColumnControllerControlProps } from '../WbuColumnControllerControl.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import { getDataAttributes } from '../../../common/utils';
import type { DividerDeprecated } from '../../../common/types';

const WbuColumnControllerControl: React.FC<
  IWbuColumnControllerControlProps
> = props => {
  const {
    id,
    className,
    fieldTitle,
    tooltipMessage,
    enableReorder,
    minimumValue,
    dividerType,
    isDisabled,
    columns,
    onChange,
    updateComponentPropsInViewer,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const divider = dividerType.toLowerCase() as DividerDeprecated;

  const tooltipInfo =
    tooltipMessage !== '' && tooltipMessage !== null
      ? {
          text: tooltipMessage,
        }
      : undefined;

  const handleColumnsChange = (value: typeof columns) => {
    updateComponentPropsInViewer({ columns: value });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ColumnControllerControl
        disabled={isDisabled}
        allowReorder={enableReorder}
        headerProps={{
          title: fieldTitle,
          tooltipInfo,
        }}
        columns={columns}
        onChange={({ newColumns }) => handleColumnsChange(newColumns)}
        minValue={minimumValue}
        divider={divider === 'none' ? undefined : divider}
      />
    </WbuContainer>
  );
};

export default WbuColumnControllerControl;
