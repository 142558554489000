import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{ alignment: 'ltr', value: '#e74c3c', label: 'Select a color' },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
    'min-width': '72px',
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
});
