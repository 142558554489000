import * as React from 'react';
import { TextButton, WixStyleReactProvider } from 'wix-style-react';
import { getDataAttributes, findKeyByObjectValue } from '../../../common/utils';
import { usePlatformIcon, usePlatformLink } from '../../../providers';
import type { IDashboardTextButtonProps } from '../DashboardTextButton.props';
import {
  SIZES,
  SKINS,
  UNDERLINES,
  WEIGHTS,
} from '../DashboardTextButton.constants';
import { dashboardTextButtonDataHook } from './constants';

import { st, classes } from './DashboardTextButton.st.css';

const DashboardTextButton: React.FC<IDashboardTextButtonProps> = props => {
  const {
    id,
    className,
    label,
    link,
    prefixIcon,
    suffixIcon,
    disabled,
    onClick = () => {},
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    showPrefix,
    showSuffix,
    size,
    skin,
    weight,
    underline,
  } = props;

  const linkProps = usePlatformLink(link);
  const platformPrefixIcon = usePlatformIcon(prefixIcon);
  const platformSuffixIcon = usePlatformIcon(suffixIcon);

  const sizeValue = findKeyByObjectValue({ object: SIZES, value: size });
  const skinValue = findKeyByObjectValue({ object: SKINS, value: skin });
  const weightValue = findKeyByObjectValue({ object: WEIGHTS, value: weight });
  const underlineValue = findKeyByObjectValue({
    object: UNDERLINES,
    value: underline,
  });

  const prefix = showPrefix ? platformPrefixIcon : undefined;
  const suffix = showSuffix ? platformSuffixIcon : undefined;

  return (
    <WixStyleReactProvider
      features={{ newColorsBranding: true }}
      id={id}
      {...getDataAttributes(props)}
      className={st(classes.root, className)}
    >
      <TextButton
        {...linkProps}
        dataHook={dashboardTextButtonDataHook}
        as={linkProps.href ? 'a' : undefined}
        skin={skinValue}
        size={sizeValue}
        onClick={onClick}
        onDoubleClick={onDblClick}
        prefixIcon={prefix}
        suffixIcon={suffix}
        disabled={disabled}
        underline={underlineValue}
        weight={weightValue}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {label}
      </TextButton>
    </WixStyleReactProvider>
  );
};

export default DashboardTextButton;
