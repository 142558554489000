import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getHeightInPixels } from '@wix/editor-elements-common-utils';

const props = withCompInfo<any, any>()(
  ['compData', 'compLayout'],
  ({ compData, compLayout }) => {
    return {
      ...{
        height: compLayout.height,
      },
      ...compData,
    };
  },
);

const css = withCompInfo<any, any>()(
  ['compLayout', 'compSingleLayout', 'isOneDocMigrated'],
  ({ compLayout, compSingleLayout, isOneDocMigrated }) => {
    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;
    return {
      '--height': height ? `${height}px` : undefined,
    };
  },
);

export default createComponentMapperModel({
  props,
  css,
});
