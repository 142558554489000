import * as React from 'react';
import { Text, WixStyleReactProvider } from 'wix-style-react';
import { findKeyByObjectValue, getDataAttributes } from '../../../common/utils';
import type { IDashboardTextProps } from '../DashboardText.props';
import { PRESETS, WEIGHTS, SIZES, ALIGNS } from '../DashboardText.constants';
import { dashboardTextDataHook } from './constants';
import { st, classes } from './DashboardText.st.css';

export type TextAlignment = 'left' | 'center' | 'right';

const DashboardText: React.FC<IDashboardTextProps> = props => {
  const {
    id,
    className,
    text,
    preset,
    weight,
    textAlignment,
    size,
    overflow,
    maxLines,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const weightValue =
    findKeyByObjectValue({ object: WEIGHTS, value: weight }) || 'normal';

  const sizeValue =
    findKeyByObjectValue({ object: SIZES, value: size }) || 'medium';

  const selectedPreset = Object.values(PRESETS).find(
    ({ value: currentPresetValue }) => currentPresetValue === preset,
  );

  const textAlignmentValue =
    findKeyByObjectValue({ object: ALIGNS, value: textAlignment }) || 'center';

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      data-text-align={textAlignmentValue}
      className={st(
        classes.root,
        {
          align: textAlignmentValue,
        },
        className,
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Text
        weight={weightValue}
        dataHook={dashboardTextDataHook}
        data-overflow={overflow}
        size={sizeValue}
        ellipsis={overflow === 'Ellipsis'}
        maxLines={maxLines}
        skin={selectedPreset?.presetValue}
        light={selectedPreset?.light}
        secondary={selectedPreset?.secondary}
      >
        {text}
      </Text>
    </WixStyleReactProvider>
  );
};

export default DashboardText;
