import * as React from 'react';
import {
  Composites,
  ColorPickerInput,
  OpacityPicker,
  TextLabel,
  InfoIcon,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuColorPickerProps } from '../WbuColorPicker.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuColorPicker.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuColorPicker: React.FC<IWbuColorPickerProps> = props => {
  const {
    id,
    className,
    label,
    tooltip,
    color,
    opacity,
    onClick,
    updateComponentPropsInViewer,
    showPercentage,
    opacitySliderEnabled,
    divider,
    onColorChange,
    onOpacityChange,
  } = props;

  const [isMount, setIsMount] = React.useState(false);
  React.useEffect(() => {
    if (isMount) {
      onColorChange?.({ type: 'onColorChange' });
    }
    setIsMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  const _onOpacityChange = (opacityValue: number) => {
    updateComponentPropsInViewer({ opacity: opacityValue });
    onOpacityChange?.({ type: 'onOpacityChange' });
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
    >
      <Composites.ColorPickerInputLabeled>
        <TextLabel type="T02" value={label} shouldTranslate={false} />
        {tooltip && <InfoIcon text={tooltip} shouldTranslate={false} />}
        <Composites.ColorPickerInput>
          <OpacityPicker
            value={opacity}
            onChange={(opacityValue: number) => _onOpacityChange(opacityValue)}
            {...(opacitySliderEnabled
              ? { opacityGradientColor: color }
              : { customFillGradient: '#116DFF' })}
            unit={showPercentage ? 'percent' : ''}
          />
          <ColorPickerInput value={color} opacity={opacity} onClick={onClick} />
        </Composites.ColorPickerInput>
      </Composites.ColorPickerInputLabeled>
    </WbuContainer>
  );
};

export default WbuColorPicker;
