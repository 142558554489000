import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import type { IWbuTextInputProps } from '../WbuTextInput.props';

export const props = withCompInfo<IWbuTextInputProps, any>()(
  ['compData'],
  ({ compData }) => ({
    ...compData,
  }),
);

export default createComponentMapperModel({
  props,
});
