
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardInfoIconProps,
  IDashboardInfoIconMapperProps,
  IDashboardInfoIconControllerProps,
  IDashboardInfoIconStateValues,
} from '../../../components/DashboardInfoIcon/DashboardInfoIcon.props';

export default withCompController<
  IDashboardInfoIconMapperProps,
  IDashboardInfoIconControllerProps,
  IDashboardInfoIconProps,
  IDashboardInfoIconStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
