
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "DashboardInfoIcon1271322014",
      {"classes":{"root":"DashboardInfoIcon1271322014__root","tooltipContent":"DashboardInfoIcon1271322014__tooltipContent"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  