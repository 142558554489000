
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuSectionDividerProps,
  IWbuSectionDividerMapperProps,
  IWbuSectionDividerControllerProps,
  IWbuSectionDividerStateValues,
} from '../../../components/WbuSectionDivider/WbuSectionDivider.props';

export default withCompController<
  IWbuSectionDividerMapperProps,
  IWbuSectionDividerControllerProps,
  IWbuSectionDividerProps,
  IWbuSectionDividerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
