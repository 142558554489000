
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWutBadgeProps,
  IWutBadgeMapperProps,
  IWutBadgeControllerProps,
  IWutBadgeStateValues,
} from '../../../components/WutBadge/WutBadge.props';

export default withCompController<
  IWutBadgeMapperProps,
  IWutBadgeControllerProps,
  IWutBadgeProps,
  IWutBadgeStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
