
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardTextProps,
  IDashboardTextMapperProps,
  IDashboardTextControllerProps,
  IDashboardTextStateValues,
} from '../../../components/DashboardText/DashboardText.props';

export default withCompController<
  IDashboardTextMapperProps,
  IDashboardTextControllerProps,
  IDashboardTextProps,
  IDashboardTextStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
