
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WutRadioButtoncomponent3495145286",
      {"classes":{"root":"WutRadioButtoncomponent3495145286__root","srOnly":"WutRadioButtoncomponent3495145286__srOnly"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  