import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      height: 480,
      width: 600,
      helpButtonUrl: '',
      helpButton: false,
      footnoteLinkUrl: '',
      footnoteLinkText: 'Learn more about terms of use',
      footnoteLink: true,
      footnoteContent: 'By proceeding, you agree to our privacy policy.',
      footnote: true,
      showFooterDivider: 'Always',
      sideActionsCheckboxTitle: 'Send me a copy via an email',
      sideActionsCheckbox: true,
      secondaryButtonSuffix: {},
      secondaryButtonPrefix: {},
      secondaryButtonLink: '',
      secondaryButtonText: 'Cancel',
      addSecondaryAction: true,
      primaryButtonDisabled: false,
      primaryButtonSuffix: {},
      primaryButtonPrefix: {},
      primaryButtonLink: '',
      primaryButtonText: 'Save',
      showFooter: true,
      removeContentPadding: false,
      showHeaderDivider: 'Always',
      subtitle: 'This is modal subtitle. You can remove it if you want.',
      title: 'Custom modal title',
      showHeader: true,
      theme: 'Standard',
    },
    ...compData,
  };
});

export default createComponentMapperModel({
  props,
});
