import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { IWutAvatarGroupProps } from '../WutAvatarGroup.props';

export const props = withCompInfo<IWutAvatarGroupProps, any>()(
  ['compData', 'compStylableClass'],
  ({ compData, compStylableClass }) => {
    return {
      ...{ avatars: [], showHiddenAvatarCount: true, maxAvatars: 5 },
      ...compData,
      stylableClassName: compStylableClass,
    };
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
