import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';

const props = withCompPreviewInfo<any, any>()(
  ['compData', 'componentViewMode'],
  ({ compData, componentViewMode }) => {
    return {
      ...{
        isEditing: componentViewMode === 'editor',
      },
      ...compData,
    };
  },
);

const css = withCompPreviewInfo()(
  ['componentViewMode'],
  ({ componentViewMode }) => {
    const isEditing = componentViewMode === 'editor';
    return {
      '--containerOutline': isEditing ? '1px dashed #868AA5' : 'none',
      width: '100%',
      top: 0,
      left: 0,
      margin: 0,
      'align-self': 'flex-start',
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
