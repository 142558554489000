import * as React from 'react';
import {
  Composites,
  TextLabel,
  ColorPickerInput,
} from '@wix/wix-base-ui/lib/esm';
import type { IWbuColorSelectorProps } from '../WbuColorSelector.types';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuColorSelector.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuColorSelector: React.FC<IWbuColorSelectorProps> = props => {
  const {
    id,
    className,
    label,
    color,
    divider,
    onColorChange,
    onChange,
    onMouseEnter,
    onMouseLeave,
    isDisabled,
    openColorPickerPanel,
  } = props;

  const [isMount, setIsMount] = React.useState(false);
  React.useEffect(() => {
    if (isMount) {
      onColorChange?.({ type: 'onColorChange' });
      onChange?.({ type: 'change' } as React.ChangeEvent);
    }
    setIsMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Composites.ColorSelectLabeled>
        <TextLabel type="T02" value={label} />
        <ColorPickerInput
          onClick={openColorPickerPanel}
          value={color}
          disabled={isDisabled}
        />
      </Composites.ColorSelectLabeled>
    </WbuContainer>
  );
};

export default WbuColorSelector;
