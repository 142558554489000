
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WutAvatarGroup4209718845",
      {"classes":{"root":"WutAvatarGroup4209718845__root","avatarContainerLayout":"WutAvatarGroup4209718845__avatarContainerLayout","avatarContainer":"WutAvatarGroup4209718845__avatarContainer","avatarWrapper":"WutAvatarGroup4209718845__avatarWrapper","avatar":"WutAvatarGroup4209718845__avatar"},"vars":{"avatarCircleSize":"--WutAvatarGroup4209718845-avatarCircleSize","spaceSize":"--WutAvatarGroup4209718845-spaceSize","overlap":"--WutAvatarGroup4209718845-overlap"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  