import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      helpButtonUrl: '',
      helpButton: false,
      footnoteLinkUrl: '',
      footnoteLinkText: '',
      footnoteLink: false,
      footnoteContent: '',
      footnote: false,
      sideActionsCheckboxTitle: '',
      sideActionsCheckbox: false,
      secondaryButtonSuffix: {},
      secondaryButtonPrefix: {},
      secondaryButtonLink: '',
      secondaryButtonText: 'Cancel',
      primaryButtonSuffix: {},
      primaryButtonPrefix: {},
      primaryButtonLink: '',
      primaryButtonText: 'Submit',
      illustration: {},
      content: '',
      title: 'Message modal title',
      theme: 'Standard',
    },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
    width: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
