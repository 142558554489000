export const SKINS = {
  standard: 'Standard',
  light: 'Light',
  destructive: 'Destructive',
  premium: 'Premium',
  'premium-light': 'Premium light',
  dark: 'Dark',
  transparent: 'Transparent',
} as const;

export const PRIORITIES = {
  primary: 'Primary',
  secondary: 'Secondary',
} as const;

export const SIZES = {
  tiny: 'Tiny',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
} as const;
