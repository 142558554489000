
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuButtonProps,
  IWbuButtonMapperProps,
  IWbuButtonControllerProps,
  IWbuButtonStateValues,
} from '../../../components/WbuButton/WbuButton.props';

export default withCompController<
  IWbuButtonMapperProps,
  IWbuButtonControllerProps,
  IWbuButtonProps,
  IWbuButtonStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
