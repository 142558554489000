
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuCornerRadiusProps,
  IWbuCornerRadiusMapperProps,
  IWbuCornerRadiusControllerProps,
  IWbuCornerRadiusStateValues,
} from '../../../components/WbuCornerRadius/WbuCornerRadius.props';

export default withCompController<
  IWbuCornerRadiusMapperProps,
  IWbuCornerRadiusControllerProps,
  IWbuCornerRadiusProps,
  IWbuCornerRadiusStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
