import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import type { SizeTypes } from '../DashboardHeading.props';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...compData,
  };
});

const css = withCompInfo()(['compData'], ({ compData }) => {
  const { size } = compData;

  const minHeights: { [K in SizeTypes]: string } = {
    'Page title H1': '38px',
    'Modal title H2': '30px',
    'Card title H3': '26px',
    'Section title H4': '26px',
    'Card section title H5': '26px',
    'Caption H6': '17px',
  };

  const minHeight = minHeights[size as SizeTypes];
  return {
    'min-height': minHeight,
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
