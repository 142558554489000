import * as React from 'react';
import { Heading, WixStyleReactProvider } from 'wix-style-react';
import { findKeyByObjectValue, getDataAttributes } from '../../../common/utils';
import { ALIGNS, SIZES } from '../DashboardHeading.constants';
import type { IDashboardHeadingProps } from '../DashboardHeading.props';
import { st, classes } from './DashboardHeading.st.css';
import { DashboardHeadingDataHook } from './constants';

const DashboardHeading: React.FC<IDashboardHeadingProps> = props => {
  const {
    id,
    className,
    text,
    size,
    invertColor,
    textAlignment,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const sizeValue =
    findKeyByObjectValue({ object: SIZES, value: size }) || 'extraLarge';

  const textAlignmentValue =
    findKeyByObjectValue({ object: ALIGNS, value: textAlignment }) || 'left';

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      className={st(classes.root, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Heading
        data-text-align={textAlignmentValue}
        dataHook={DashboardHeadingDataHook}
        light={invertColor}
        size={sizeValue}
        className={st(classes.textAlignment, {
          align: textAlignmentValue,
        })}
      >
        {text}
      </Heading>
    </WixStyleReactProvider>
  );
};

export default DashboardHeading;
