import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';

const props = withCompPreviewInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...compData,
  };
});

const css = withCompPreviewInfo()([], () => {
  return {
    height: 'auto',
    width: 'auto',
  };
});

export default createComponentPreviewMapperModel({
  props,
  css,
});
