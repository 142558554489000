import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import type { IWbuToggleSwitchProps } from '../WbuToggleSwitch.props';

export const props = withCompInfo<IWbuToggleSwitchProps, any>()(
  ['compData'],
  ({ compData }) => ({
    ...compData,
  }),
);

export default createComponentMapperModel({
  props,
});
