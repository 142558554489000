import React from 'react';
import { classes, vars } from './usePlatformImage.st.css';

const usePlatformImage = ({
  svgId,
  width,
  height,
}: {
  svgId: string;
  width: string;
  height: string;
}) => {
  return svgId ? (
    <div
      className={classes.root}
      style={{ [vars.width]: width, [vars.height]: height }}
      dangerouslySetInnerHTML={{ __html: svgId }}
    />
  ) : undefined;
};

export default usePlatformImage;
