import React from 'react';
import { TabbedContent } from 'wix-ui-tpa/cssVars';
import { getDataAttributes } from '../../../common/utils';
import type { IWutTabbedContentProps } from '../WutTabbedContent.types';
import { st, classes } from './style/WutTabbedContent.component.st.css';

/**
 * TODO
 * Wire the logic, what h֜appens when we click on a tab? what other properties should be wired?
 * Wire the content per tab
 */

const setItemsWithId = (items: IWutTabbedContentProps['items']) => {
  return items.map(x => {
    return {
      ...x,
      id: x.value,
    };
  });
};

const WutTabbedContent: React.FC<IWutTabbedContentProps> = props => {
  const { id, activeTabIndex, className, stylableClassName, items } = props;

  return (
    <div id={id} className={className} {...getDataAttributes(props)}>
      <TabbedContent
        items={setItemsWithId(items)}
        activeTabIndex={activeTabIndex}
        onTabClick={() => {}}
        className={st(classes.root, stylableClassName)}
      >
        {tabId => {
          return (
            <div style={{ padding: '24px', backgroundColor: 'white' }}>
              Tab {tabId + 1} content
            </div>
          );
        }}
      </TabbedContent>
    </div>
  );
};

export default WutTabbedContent;
