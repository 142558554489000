
import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';


const props = withCompInfo<any, any>()(
  ['compData'],
  ({compData}) => {
      
    return {
      ...{ selectedFont: 'arial',
colorPickerInput: '#EEE',
numericInput: 0,
slider: 1,
link: {'$ref':'document_data.json#/LinkReference'},
thumbnails: {},
toggleSwitch: false,
radioButtons: 'Options 1',
dropdown: 'Options 1',
options: [],
longString: 'LongString',
shortString: 'ShortString',
 },
...compData,

    };
  },
);




export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
