
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuSliderProps,
  IWbuSliderMapperProps,
  IWbuSliderControllerProps,
  IWbuSliderStateValues,
} from '../../../components/WbuSlider/WbuSlider.props';

export default withCompController<
  IWbuSliderMapperProps,
  IWbuSliderControllerProps,
  IWbuSliderProps,
  IWbuSliderStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
