
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardNumberInputProps,
  IDashboardNumberInputMapperProps,
  IDashboardNumberInputControllerProps,
  IDashboardNumberInputStateValues,
} from '../../../components/DashboardNumberInput/DashboardNumberInput.props';

export default withCompController<
  IDashboardNumberInputMapperProps,
  IDashboardNumberInputControllerProps,
  IDashboardNumberInputProps,
  IDashboardNumberInputStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
