
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardIconButtonProps,
  IDashboardIconButtonMapperProps,
  IDashboardIconButtonControllerProps,
  IDashboardIconButtonStateValues,
} from '../../../components/DashboardIconButton/DashboardIconButton.props';

export default withCompController<
  IDashboardIconButtonMapperProps,
  IDashboardIconButtonControllerProps,
  IDashboardIconButtonProps,
  IDashboardIconButtonStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
