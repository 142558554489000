import * as React from 'react';
import {
  InfoIcon,
  SecondaryMediaImage,
  Composites,
  TextLabel,
  Button,
} from '@wix/wix-base-ui';
import {
  Delete,
  Upload,
  Replace,
} from '@wix/wix-ui-icons-common/classic-editor';
import type { IWbuSecondaryMediaProps } from '../WbuSecondaryMedia.props';
import './WbuSecondaryMedia.global.scss';
import WbuContainer from '../../WbuContainer/WbuContainer';
import { getDataAttributes } from '../../../common/utils';

const WbuSecondaryMedia: React.FC<IWbuSecondaryMediaProps> = props => {
  const {
    id,
    className,
    content,
    label,
    tooltipText,
    onClick,
    onChange,
    updateComponentPropsInViewer,
  } = props;

  const children = () => {
    if (!content.svgId) {
      return;
    }
    const svgContainerDataHook = 'svg-container-secondary-media-svg';
    const iconContainerStyle = `<style>
          [data-hook=${svgContainerDataHook}] svg {
          max-width: 154px;
          min-width: 82px;
          width: calc(100% - 86px);
          height: 82px;
        }
        </style>`;

    return (
      <div
        data-hook={svgContainerDataHook}
        dangerouslySetInnerHTML={{ __html: iconContainerStyle + content.svgId }}
      />
    );
  };

  const resetIcon = () => {
    updateComponentPropsInViewer({ content: { svgId: '' } });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer id={id} {...getDataAttributes(props)} className={className}>
      <Composites.SecondaryMediaLabeled>
        <TextLabel type="T02" value={label} />
        {tooltipText && <InfoIcon text={tooltipText} />}
        <SecondaryMediaImage children={children()} shouldTranslate={false} />
        <Button onClick={onClick} className="btn-sm">
          {content.svgId ? <Replace size="24px" /> : <Upload size="24px" />}
        </Button>
        {content.svgId && (
          <Button onClick={resetIcon} className="btn-sm">
            <Delete size="24px" />
          </Button>
        )}
      </Composites.SecondaryMediaLabeled>
    </WbuContainer>
  );
};

export default WbuSecondaryMedia;
