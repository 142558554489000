import * as React from 'react';
import { Checkbox, FormField } from 'wix-style-react';
import type {
  CheckboxLabelSize,
  CheckboxSelectionArea,
  CheckboxSelectionAreaSkin,
  CheckboxVAlign,
} from 'wix-style-react';
import { getDataAttributes } from '../../../common/utils';
import type { IWsrCheckboxProps } from '../WsrCheckbox.props';
import { SELECTION_AREAS, AREA_SKINS } from '../WsrCheckbox.constants';

const WsrCheckbox: React.FC<IWsrCheckboxProps> = props => {
  const {
    id,
    className,
    label,
    checked,
    showTooltip,
    tooltipContent,
    disabled,
    required,
    size,
    selectionAreaSkin,
    selectionArea,
    vAlign,
    onChange = () => {},
    updateComponentPropsInViewer,
  } = props;

  const handleOnChange = () => {
    updateComponentPropsInViewer({ checked: !checked });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const selectionAreaSkinValue =
    selectionAreaSkin === AREA_SKINS.plain
      ? null
      : selectionAreaSkin.toLowerCase();

  const selectionAreaValue = selectionAreaSkinValue
    ? Object.keys(SELECTION_AREAS).find(
        key =>
          SELECTION_AREAS[key as keyof typeof SELECTION_AREAS] ===
          selectionArea,
      )
    : undefined;

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <FormField
        required={required}
        infoContent={showTooltip ? tooltipContent : undefined}
      >
        <Checkbox
          checked={checked}
          disabled={disabled}
          size={size.toLowerCase() as CheckboxLabelSize}
          selectionAreaSkin={
            selectionAreaSkinValue as CheckboxSelectionAreaSkin
          }
          selectionArea={selectionAreaValue as CheckboxSelectionArea}
          vAlign={vAlign.toLowerCase() as CheckboxVAlign}
          onChange={handleOnChange}
        >
          {label}
        </Checkbox>
      </FormField>
    </div>
  );
};

export default WsrCheckbox;
