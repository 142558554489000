import type { TextSize } from 'wix-style-react';

export const PRESETS = {
  STANDARD: {
    value: 'Standard',
    presetValue: 'standard',
    secondary: false,
    light: false,
  },
  STANDARD_SECONDARY: {
    value: 'Standard secondary',
    presetValue: 'standard',
    secondary: true,
    light: false,
  },
  LIGHT: {
    value: 'Light',
    presetValue: 'standard',
    light: true,
    secondary: false,
  },
  LIGHT_SECONDARY: {
    value: 'Light secondary',
    presetValue: 'standard',
    secondary: true,
    light: true,
  },
  SUCCESS: {
    value: 'Success',
    presetValue: 'success',
    secondary: false,
    light: false,
  },
  DESTRUCTIVE: {
    value: 'Destructive',
    presetValue: 'error',
    secondary: false,
    light: false,
  },
  PRIMARY: {
    value: 'Primary',
    presetValue: 'primary',
    secondary: false,
    light: false,
  },
  PREMIUM: {
    value: 'Premium',
    presetValue: 'premium',
    secondary: false,
    light: false,
  },
  DISABLED: {
    value: 'Disabled',
    presetValue: 'disabled',
    secondary: false,
    light: false,
  },
} as const;

export const SIZES = {
  tiny: 'Tiny',
  small: 'Small',
  medium: 'Medium',
} as const;

export type SizeTypes = (typeof SIZES)[TextSize];

export const WEIGHTS = {
  bold: 'Bold',
  normal: 'Normal',
  thin: 'Thin',
} as const;

export const ALIGNS = {
  left: 'Left',
  center: 'Center',
  right: 'Right',
} as const;
export const OVERFLOWS = {
  wrap: 'Wrap',
  ellipsis: 'Ellipsis',
} as const;
