import * as React from 'react';
import { DatePicker, FormField } from 'wix-style-react';
import type { DatePickerStatus } from 'wix-style-react';
import type { IWsrDatePickerProps } from '../WsrDatePicker.props';
import { STATUSES, ACTIVE_STATES } from '../WsrDatePicker.constants';
import { BORDERS } from '../../DashboardInput/DashboardInput.constants';
import { getDataAttributes, findKeyByObjectValue } from '../../../common/utils';

const WsrDatePicker: React.FC<IWsrDatePickerProps> = props => {
  const {
    id,
    className,
    label,
    placeholderText,
    showTooltip,
    tooltipContent,
    clearButton,
    errorStatusMessage,
    warningStatusMessage,
    loadingStatusMessage,
    status,
    activeState,
    required,
    size,
    onChange = () => {},
    border,
    value,
    updateComponentPropsInViewer,
  } = props;

  const [datePickerValue, setDatePickerValue] = React.useState<
    Date | undefined
  >();

  React.useEffect(() => {
    setDatePickerValue(value);
  }, [value]);

  const handleOnChange = (newValue: Date) => {
    setDatePickerValue(newValue);
    updateComponentPropsInViewer({ value: newValue });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const datePickerStatus = (
    status === STATUSES.none
      ? undefined
      : Object.keys(STATUSES).find(key => STATUSES[key] === status)
  ) as DatePickerStatus;

  const statusToMessage = {
    [STATUSES.error]: errorStatusMessage,
    [STATUSES.warning]: warningStatusMessage,
    [STATUSES.loading]: loadingStatusMessage,
  };

  const datePickerState = Object.values(ACTIVE_STATES).find(
    ({ value: currentValue }) => currentValue === activeState,
  );

  const borderValue = findKeyByObjectValue({ object: BORDERS, value: border });

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <FormField
        label={label}
        infoContent={showTooltip ? tooltipContent : undefined}
        required={required}
        id={id}
      >
        <DatePicker
          width="100%"
          onChange={handleOnChange}
          placeholderText={placeholderText}
          clearButton={clearButton}
          status={datePickerStatus}
          statusMessage={statusToMessage[status]}
          readOnly={datePickerState?.readOnly}
          disabled={datePickerState?.disabled}
          size={size.toLowerCase() as 'small' | 'medium' | 'large'}
          inputProps={{ border: borderValue, id }}
          value={datePickerValue}
        />
      </FormField>
    </div>
  );
};

export default WsrDatePicker;
