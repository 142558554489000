
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrCustomModalLayout2890301957",
      {"classes":{"root":"WsrCustomModalLayout2890301957__root","contentContainer":"WsrCustomModalLayout2890301957__contentContainer"},"vars":{"containerOutline":"--containerOutline"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  