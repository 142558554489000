import * as React from 'react';
import {
  Composites,
  TextLabel,
  InfoIcon,
  DropDown,
  DropDownOption,
} from '@wix/wix-base-ui/lib/esm';
import type { ManagedOptionDataType } from '@wix/editor-elements-types/leanComponents';
import type { IWbuDropdownProps } from '../WbuDropdown.props';
import WbuContainer from '../../WbuContainer/WbuContainer';

import './WbuDropdown.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuDropdown: React.FC<IWbuDropdownProps> = props => {
  const {
    id,
    className,
    fieldTitle,
    tooltipInfo,
    onChange,
    options,
    isDisabled,
    placeholder,
    divider,
    updateComponentPropsInViewer,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const _onChange = (selectedOptionValue: string) => {
    const newOptions = options.map(option => {
      if (option.value === selectedOptionValue) {
        return {
          ...option,
          isDefault: true,
        };
      }
      return {
        ...option,
        isDefault: false,
      };
    });
    updateComponentPropsInViewer({ options: newOptions });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const selectedOption = options.find(
    (option: ManagedOptionDataType) => option.isDefault,
  );

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Composites.DropDownLabeled>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        {fieldTitle && <TextLabel type="T02" value={fieldTitle} />}
        <DropDown
          placeholder={placeholder}
          value={selectedOption ? selectedOption.value : undefined}
          onChange={_onChange}
          disabled={isDisabled}
        >
          {options.map((option, key) => (
            <DropDownOption
              key={key.toString()}
              value={option.value}
              label={option.label}
            />
          ))}
        </DropDown>
      </Composites.DropDownLabeled>
    </WbuContainer>
  );
};

export default WbuDropdown;
