type Dataset = Record<string, string>;

export const getDataAttributes = (props: any): Dataset => {
  return Object.entries(props).reduce((acc: Dataset, [key, val]) => {
    if (key.includes('data-')) {
      acc[key] = val as string;
    }
    return acc;
  }, {});
};

export const toStringPx = (value: number) => `${value}px`;

export const stringPxToNumber = (value?: string) =>
  value ? Number(value.replace('px', '')) : 0;

export function findKeyByObjectValue<
  T extends { [key: string]: unknown },
  U extends string,
>({ object, value }: { object: T; value: U }) {
  const key = Object.keys(object).find(k => object[k] === value);
  return key as keyof T | undefined;
}

export const transformGetValue = (value: string) =>
  value.toUpperCase().replace(' ', '_');
export const transformSetValue = (value: string) =>
  value.toLowerCase().replace('_', ' ');

export const isValueInOptions = <
  T extends Array<{ label: string; value: string }>,
>(
  _value: string,
  options: T,
) => {
  return !!options.find(({ value }: { value: string }) => value === _value);
};

export const areOptionValuesUnique = <
  T extends Array<{ label: string; value: string }>,
>(
  options: T,
) => {
  const uniqValues = new Set(options.map(({ value }) => value.trim()));

  return uniqValues.size === options.length;
};

export const findNonUniqueValueOptions = <
  T extends Array<{ label: string; value: string }>,
>(
  options: T,
) => {
  const valueCounts = options.reduce<{ [key: string]: number }>(
    (counts, option) => {
      counts[option.value] = (counts[option.value] || 0) + 1;
      return counts;
    },
    {},
  );

  return options.filter(option => valueCounts[option.value] > 1);
};

export const removeDuplicateOptions = <
  T extends { label: string; value: string },
>(
  options: Array<T>,
) => {
  const seenOptions = new Set();

  return options.filter(option => {
    const optionKey = `${option.label}_${option.value}`;
    const isDuplicate = seenOptions.has(optionKey);
    seenOptions.add(optionKey);
    return !isDuplicate;
  });
};

export const findDuplicateOptions = <
  T extends Array<{ label: string; value: string }>,
>(
  options: T,
) => {
  const seenOptions = new Set();

  return options.filter(option => {
    const optionString = JSON.stringify(option);
    const isDuplicate = seenOptions.has(optionString);
    seenOptions.add(optionString);
    return isDuplicate;
  });
};
