
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IControlTypesProps,
  IControlTypesMapperProps,
  IControlTypesControllerProps,
  IControlTypesStateValues,
} from '../../../components/ControlTypes/ControlTypes.props';

export default withCompController<
  IControlTypesMapperProps,
  IControlTypesControllerProps,
  IControlTypesProps,
  IControlTypesStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
