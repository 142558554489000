import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      border: 'Standard',
      size: 'Medium',
      required: false,
      activeState: 'Active',
      loadingStatusMessage: '',
      warningStatusMessage: '',
      errorStatusMessage: '',
      status: 'Default',
      clearButton: false,
      tooltipContent: '',
      showTooltip: false,
      placeholderText: '',
      label: 'Date Picker',
    },
    ...compData,
  };
});

const css = withCompInfo()([], () => {
  return {
    height: 'auto',
    'min-width': '80px',
  };
});

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
});
