import type { AvatarGroupItem } from 'wix-ui-tpa/cssVars';

export const translationKeys = {
  componentName: 'dsgnsys_WutAvatarGroup_component_label',
  layoutPanel: {
    title: 'dsgnsys_WutAvatarGroup_panelTitle_layout',
    arrangedTitle: 'dsgnsys_WutAvatarGroup_arrangement_title',
    horizontalArrange: 'dsgnsys_WutAvatarGroup_arrangement_horizontal_label',
    verticalArrange: 'dsgnsys_WutAvatarGroup_arrangement_vertical_label',
    avatarsToShow: 'dsgnsys_WutAvatarGroup_max_title',
    hiddenAvatarsCount: 'dsgnsys_WutAvatarGroup_indicate_more_title',
    overlapAvatars: 'dsgnsys_WutAvatarGroup_overlap_title',
    spacingBetween: 'dsgnsys_WutAvatarGroup_spacing_between_title',
    negativeSpacing: 'dsgnsys_WutAvatarGroup_overlap_size_title',
    avatarSize: 'dsgnsys_WutAvatarGroup_group_size_title',
    avatarsDirection: 'dsgnsys_WutAvatarGroup_direction_title',
    avatarsDirectionRtl: 'dsgnsys_WutAvatarGroup_direction_right_button',
    avatarsDirectionLtr:
      'dsgnsys_WutAvatarGroup_direction_left_button_aria_label',
  },
  settingsPanel: {
    changePanel: 'dsgnsys_WutAvatarGroup_gfpp_layout',
    avatarGroup: 'dsgnsys_WutAvatarGroup_component_label',
  },
  stylablePanel: {
    title: 'dsgnsys_WutAvatarGroup_panelTitle_design',
    avatar: 'dsgnsys_WutAvatarGroup_design_avatar_label',
    text: 'dsgnsys_WutAvatarGroup_design_text_label',
    fill: 'StylablePanel_Design_Borders_Fill_Tooltip',
    background: 'StylablePanel_Design_Fills_Tab_Title',
    corners: 'StylablePanel_Design_Corners_Tab_Title',
    borders: 'StylablePanel_Design_Borders_Tab_Title',
    shadows: 'StylablePanel_Design_Shadows_Tab_Title',
    avatarSkin: 'StylablePanel_Design_Button_Icon_Tab_Label',
    textSection: 'dsgnsys_WutAvatarGroup_design_text_label',
  },
};

export const defaultAvatars: Array<AvatarGroupItem> = Array.from({
  length: 8,
}).map(() => ({}));

export const TestIds = {
  baseAvatarGroup: 'base-avatar-group',
  layoutPanel: {
    root: 'layoutPanelRoot',
    circleSizeSlider: 'layoutCircleSizeSlider',
    spaceSizeSlider: 'layoutSpaceSizeSlider',
    orientationThumbnails: 'orientationThumbnails',
    directionThumbnails: 'directionThumbnails',
    maxAvatarsInput: 'maxAvatarsInput',
    hiddenAvatarsIndicationToggle: 'hiddenAvatarsIndicationToggle',
    avatarsOverlapToggle: 'avatarsOverlapToggle',
  },
};

export const Selectors = {
  root: '.root',
  avatarContainer: '.root::avatarContainer:not(:first-of-type)',
  avatarWrapper: '.root::avatarWrapper',
  avatar: '.root::avatar',
  avatarContent: '.root::avatar::content',
  avatarTextContent: '.root::avatar::content[data-hook="text-container"]',
  avatarAnonymousSvg: '.root::avatar::anonymous',
};

export const CssVarPrefix = {
  global: '--',
  scoped: '--WutAvatarGroup-',
  corvid: '--corvid-',
};

export const CssVars = {
  avatarCircleSize: 'avatarCircleSize',
  spaceSize: 'spaceSize',
  overlap: 'overlap',
};
