export const SIZES = {
  extraLarge: 'Page title H1',
  large: 'Modal title H2',
  medium: 'Card title H3',
  small: 'Section title H4',
  tiny: 'Card section title H5',
  extraTiny: 'Caption H6',
} as const;

export const ALIGNS = {
  left: 'Left',
  center: 'Center',
  right: 'Right',
} as const;
