import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import type { IWbuDropdownProps } from '../WbuDropdown.props';

export const props = withCompInfo<IWbuDropdownProps, any>()(
  ['compData'],
  ({ compData }) => ({
    ...compData,
  }),
);

export default createComponentMapperModel({
  props,
});
