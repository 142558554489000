import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

import type { IWbuButtonProps } from '../WbuButton.props';

export const props = withCompInfo<IWbuButtonProps, any>()(
  ['compData'],
  ({ compData }) => ({
    ...compData,
    link: compData.link?.resolvedLink,
  }),
);

export default createComponentMapperModel({
  props,
});
