export const LABEL_POSITIONS = {
  top: 'Top',
  left: 'Left',
  right: 'Right',
} as const;
export const PREFIX_AND_SUFFIX_TYPES = {
  icon: 'Icon',
  text: 'Text',
} as const;
export type PrefixAndSuffixTypeOptions =
  (typeof PREFIX_AND_SUFFIX_TYPES)[keyof typeof PREFIX_AND_SUFFIX_TYPES];
export const SIZES = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
} as const;
export const BORDERS = {
  standard: 'Default',
  round: 'Round',
  bottomLine: 'Line',
  none: 'None',
} as const;
