import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';

const css = withCompPreviewInfo()(
  ['componentViewMode'],
  ({ componentViewMode }) => {
    const isEditing = componentViewMode === 'editor';
    return {
      '--containerOutline': isEditing ? '1px dashed #868AA5' : 'none',
    };
  },
);

export default createComponentPreviewMapperModel({
  css,
});
