
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuEmptyStateProps,
  IWbuEmptyStateMapperProps,
  IWbuEmptyStateControllerProps,
  IWbuEmptyStateStateValues,
} from '../../../components/WbuEmptyState/WbuEmptyState.props';

export default withCompController<
  IWbuEmptyStateMapperProps,
  IWbuEmptyStateControllerProps,
  IWbuEmptyStateProps,
  IWbuEmptyStateStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
