
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrDropdownProps,
  IWsrDropdownMapperProps,
  IWsrDropdownControllerProps,
  IWsrDropdownStateValues,
} from '../../../components/WsrDropdown/WsrDropdown.props';

export default withCompController<
  IWsrDropdownMapperProps,
  IWsrDropdownControllerProps,
  IWsrDropdownProps,
  IWsrDropdownStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
