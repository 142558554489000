import * as React from 'react';
import { Composites, RichText } from '@wix/wix-base-ui/lib/esm';
import type { IWbuRichTextProps } from '../WbuRichText.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuRichText.global.scss';
import { getDataAttributes } from '../../../common/utils';
import { usePlatformLink } from '../../../providers';

const WbuRichText: React.FC<IWbuRichTextProps> = props => {
  const {
    id,
    className,
    richTextContent,
    richTextLink,
    richTextLabel,
    alignTextToCenter,
    divider,
    onClick,
    link,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const linkProps = usePlatformLink(link);

  const anchor = (
    <a
      style={{ whiteSpace: 'nowrap' }}
      href={richTextLink}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
      {...linkProps}
    >
      {richTextLabel}
    </a>
  );

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Composites.RichText
        className={alignTextToCenter ? 'composite-centered' : ''}
      >
        <RichText>
          {richTextContent}&nbsp;{anchor}
        </RichText>
      </Composites.RichText>
    </WbuContainer>
  );
};

export default WbuRichText;
