(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@wix/image-kit"), require("lodash"), require("react"), require("react-dom"), require("wix-base-ui"));
	else if(typeof define === 'function' && define.amd)
		define("rb_dsgnsys.thunderboltPreview", ["imageClientApi", "lodash", "react", "reactDOM", "wix-base-ui"], factory);
	else if(typeof exports === 'object')
		exports["rb_dsgnsys.thunderboltPreview"] = factory(require("@wix/image-kit"), require("lodash"), require("react"), require("react-dom"), require("wix-base-ui"));
	else
		root["rb_dsgnsys.thunderboltPreview"] = factory(root["__imageClientApi__"], root["_"], root["React"], root["ReactDOM"], root["wix-base-ui"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__2662__, __WEBPACK_EXTERNAL_MODULE__484__, __WEBPACK_EXTERNAL_MODULE__5329__, __WEBPACK_EXTERNAL_MODULE__5561__, __WEBPACK_EXTERNAL_MODULE__9699__) {
return 