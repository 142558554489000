
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrDatePickerProps,
  IWsrDatePickerMapperProps,
  IWsrDatePickerControllerProps,
  IWsrDatePickerStateValues,
} from '../../../components/WsrDatePicker/WsrDatePicker.props';

export default withCompController<
  IWsrDatePickerMapperProps,
  IWsrDatePickerControllerProps,
  IWsrDatePickerProps,
  IWsrDatePickerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
