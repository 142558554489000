
import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';


const props = withCompInfo<any, any>()(
  ['compData'],
  ({compData}) => {
      
    return {
      ...{ content: {},
tooltipText: '',
label: 'What logo would you display?',
 },
...compData,

    };
  },
);




export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
