export const SIZES = {
  tiny: 'Tiny',
  small: 'Small',
  medium: 'Medium',
} as const;

export const WEIGHTS = {
  thin: 'Regular',
  normal: 'Medium',
} as const;

export const UNDERLINES = {
  none: 'Never',
  onHover: 'On hover',
  always: 'Always',
} as const;

export const SKINS = {
  standard: 'Standard',
  premium: 'Premium',
  light: 'Light',
  dark: 'Dark',
  destructive: 'Destructive',
} as const;
