import * as React from 'react';
import {
  FormField,
  ToggleSwitch,
  WixStyleReactProvider,
} from 'wix-style-react';
import {
  LABEL_POSITIONS,
  SIZES,
  SKINS,
} from '../DashboardToggleSwitch.constants';
import type { IDashboardToggleSwitchProps } from '../DashboardToggleSwitch.props';
import { getDataAttributes, findKeyByObjectValue } from '../../../common/utils';
import {
  dashboardFormFieldHook,
  DashboardToggleSwitchHook,
  DashboardToggleSwitchWrapperHook,
} from './constants';
import { classes } from './DashboardToggleSwitch.st.css';

const DashboardToggleSwitch: React.FC<IDashboardToggleSwitchProps> = props => {
  const {
    id,
    className,
    label,
    checked,
    showTooltip,
    tooltip,
    disabled,
    size,
    labelPosition,
    skin,
    onChange = () => {},
    updateComponentPropsInViewer,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const handleOnChange = () => {
    updateComponentPropsInViewer({ checked: !checked });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  const sizeValue =
    findKeyByObjectValue({ value: size, object: SIZES }) || 'large';

  const labelPlacementValue =
    findKeyByObjectValue({ value: labelPosition, object: LABEL_POSITIONS }) ||
    'right';

  const skinValue =
    findKeyByObjectValue({ value: skin, object: SKINS }) || 'standard';

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      className={className}
      onClick={!disabled ? handleOnChange : undefined}
      dataHook={DashboardToggleSwitchWrapperHook}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.root}>
        <FormField
          label={label}
          labelPlacement={labelPlacementValue}
          dataHook={dashboardFormFieldHook}
          infoContent={showTooltip && tooltip}
        >
          <div className={classes.toggleSwitchWrapper}>
            <ToggleSwitch
              dataHook={DashboardToggleSwitchHook}
              checked={checked}
              size={sizeValue}
              skin={skinValue}
              disabled={disabled}
            />
          </div>
        </FormField>
      </div>
    </WixStyleReactProvider>
  );
};

export default DashboardToggleSwitch;
