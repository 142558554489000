import * as React from 'react';
import { FormField, InputArea } from 'wix-style-react';
import type { InputAreaProps } from 'wix-style-react';
import type { IWsrTextAreaProps } from '../WsrTextArea.props';
import { getDataAttributes } from '../../../common/utils';

const WsrTextArea: React.FC<
  IWsrTextAreaProps & { height: number | undefined }
> = props => {
  const {
    id,
    className,
    autoSelect,
    hasCounter,
    infoContent,
    label,
    labelPlacement,
    maxLength,
    minHeight,
    onChange,
    onKeyPress,
    placeholder,
    readOnly,
    required,
    size,
    status,
    statusMessage,
    value,
    height,
  } = props;

  const [charCount, setCharCount] = React.useState(0);
  const [localValue, setLocalValue] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (maxLength && value.length > maxLength) {
      return;
    }
    setLocalValue(value);
  }, [value, maxLength]);

  const handleOnChange: InputAreaProps['onChange'] = event => {
    if (maxLength && event.target.value.length > maxLength) {
      return;
    }

    setLocalValue(event.target.value);
    setCharCount(event.target.value.length);
    onChange?.({ type: 'change', value: event.target.value });
  };

  const handleKeyDown: InputAreaProps['onKeyDown'] = event => {
    onKeyPress?.({ type: 'keyPress', key: event.key });
  };

  const getMinHeight = () => {
    const bottomPadding = props.labelPlacement === 'top' ? 24 : 0;
    return minHeight || `${height ? height - bottomPadding : 0}px`;
  };

  const charCountProp =
    maxLength === 0 || !hasCounter ? undefined : maxLength - charCount;

  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <FormField
        label={label}
        infoContent={infoContent}
        labelPlacement={labelPlacement}
        charCount={charCountProp}
        required={required}
        stretchContent
      >
        <InputArea
          autoSelect={autoSelect}
          maxLength={maxLength}
          minHeight={getMinHeight()}
          placeholder={placeholder}
          readOnly={readOnly}
          status={status === 'none' ? undefined : status}
          statusMessage={statusMessage}
          size={size}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          value={localValue}
        />
      </FormField>
    </div>
  );
};

export default WsrTextArea;
