import React, { useState, useMemo, useEffect } from 'react';
import { BadgeBase } from 'wix-ui-tpa/cssVars';
import classNames from 'clsx';
import type { IWutBadgeProps } from '../WutBadge.props';
import { isIconComposition, isTextComposition } from '../utils/WutBadgeUtils';
import type { CompositionType } from '../utils/WutBadgeUtils';
import { TestIds } from '../constants';
import { classes, st } from './style/WutBadge.component.st.css';

const BadgeIcon = ({
  iconData,
  alt,
  composition,
  shouldSkipScreenReader,
}: {
  iconData: { svgId: string };
  alt: string;
  composition: CompositionType;
  shouldSkipScreenReader: boolean;
}) => {
  const [svgWithAttributes, setSvgWithAttributes] = useState(iconData.svgId);

  useEffect(() => {
    const parser = new DOMParser();
    const serializer = new XMLSerializer();
    const iconHtmlDocument = parser.parseFromString(
      iconData.svgId,
      'image/svg+xml',
    );
    const svgElement = iconHtmlDocument.documentElement;

    const role = svgElement.getAttribute('role');
    const ariaLabel = svgElement.getAttribute('aria-label');
    !ariaLabel && svgElement.setAttribute('aria-label', alt);
    !role && svgElement.setAttribute('role', 'img');

    setSvgWithAttributes(serializer.serializeToString(iconHtmlDocument));
  }, [iconData, alt]);

  return iconData?.svgId && isIconComposition(composition) ? (
    <div
      dangerouslySetInnerHTML={{
        __html: svgWithAttributes,
      }}
      aria-hidden={shouldSkipScreenReader}
      className={classes.icon}
      data-testid={TestIds.badgeIcon}
    />
  ) : null;
};

/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabilities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const Badge: React.FC<IWutBadgeProps> = props => {
  const { id, text, icon: iconData, composition, alt, className } = props;
  const { stylableClassName } = props as any;

  const [textElement, setTextElement] = useState<HTMLSpanElement | null>(null);

  const isTextShown = useMemo(
    () =>
      isTextComposition(composition) &&
      !!textElement &&
      getComputedStyle(textElement).getPropertyValue('display') !== 'none',
    [composition, textElement],
  );

  return (
    <div
      id={id}
      className={classNames(className, classes.outerWrapper)}
      data-testid={TestIds.wrapper}
    >
      <BadgeBase
        icon={
          <BadgeIcon
            iconData={iconData}
            alt={alt}
            composition={composition}
            shouldSkipScreenReader={isTextShown}
          />
        }
        className={st(classes.root, stylableClassName)}
        data-testid={TestIds.baseBadge}
      >
        {isTextComposition(composition) && (
          <span
            id={`text-${id}`}
            className={classes.text}
            data-testid={TestIds.badgeText}
            ref={setTextElement}
          >
            {text}
          </span>
        )}
      </BadgeBase>
    </div>
  );
};

export default Badge;
