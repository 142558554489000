import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(
  ['compData', 'compMeshProps'],
  ({ compData, compMeshProps }) => {
    return {
      ...{
        showBackButton: false,
        secondaryButtonValue: 'Cancel',
        showSecondaryButton: true,
        primaryButtonValue: 'Save',
        showPrimaryButton: true,
        subtitle: '',
        editableTitleAutoFocus: false,
        editableTitleMaxLength: 120,
        editableTitleDefaultValue: '',
        editableTitleValue: 'Untitled Page',
        title: 'Untitled Page',
        editableTitle: false,
        pageHeight: 1000,
        formLayout: 'two columns',
        pageType: 'form',
      },
      ...compData,

      meshProps: compMeshProps,
    };
  },
);

const css = withCompInfo()([], () => {
  return {
    width: '100%',
    top: 0,
    left: 0,
    margin: 0,
    'align-self': 'flex-start',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
