
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WutAvatar3213860859",
      {"classes":{"root":"WutAvatar3213860859__root","avatarWrapper":"WutAvatar3213860859__avatarWrapper","dummy-class-for-ci-fix":"WutAvatar3213860859__dummy-class-for-ci-fix"},"vars":{"circleSize":"--WutAvatar3213860859-circleSize"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  