import * as React from 'react';
import IllustrationPlaceholder from './IllustrationPlaceholder.svg';

export interface RenderSvgProps {
  dataHook?: string;
  svgId?: string;
}

const RenderSvg: React.FC<RenderSvgProps> = props => {
  const { svgId, dataHook } = props;

  const svgContent = !!svgId && svgId.includes('<svg') ? svgId : '';

  return (
    (svgContent && (
      <div
        data-hook={dataHook}
        dangerouslySetInnerHTML={{ __html: svgContent }}
      />
    )) || <IllustrationPlaceholder data-hook={dataHook} />
  );
};

export default RenderSvg;
