import * as React from 'react';
import {
  Composites,
  TextLabel,
  InfoIcon,
  DatePickerInput,
} from '@wix/wix-base-ui/lib/esm';

import WbuContainer from '../../WbuContainer/WbuContainer';
import type { IWbuDatePickerProps } from '../WbuDatePicker.props';

import './WbuDatePicker.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuDatePicker: React.FC<IWbuDatePickerProps> = props => {
  const {
    id,
    className,
    divider,
    label,
    tooltip,
    placeholder,
    value,
    updateComponentPropsInViewer,
    onChange,
  } = props;

  const _onChange = (val: Date) => {
    updateComponentPropsInViewer({ value: val });
    onChange?.({ type: 'change' } as React.ChangeEvent);
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
    >
      <Composites.DatePickerLabeled>
        <InfoIcon text={tooltip} />
        {label && <TextLabel type="T02" value={label} />}
        <DatePickerInput
          onChange={_onChange}
          value={value}
          placeholder={placeholder}
          stickToTop
        />
      </Composites.DatePickerLabeled>
    </WbuContainer>
  );
};

export default WbuDatePicker;
