export const THEME_OPTIONS = {
  standard: 'Standard',
  premium: 'Premium',
  destructive: 'Destructive',
};

export const HEADER_DIVIDER_VISIBILITY = {
  auto: 'Auto',
  always: 'Always',
  never: 'Never',
};
