import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...{
      dividerType: 'None',
      minimumValue: 1,
      enableReorder: true,
      tooltipMessage: '',
      fieldTitle: 'Column controller',
    },
    ...{},
    ...compData,
    columns: [
      { id: '1', value: 50 },
      { id: '2', value: 50 },
    ],
  };
});

const css = withCompInfo()([], () => {
  return {
    width: '100%',
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
