export const helpIds = {
  layoutPanel: '',
};

export const TestIds = {
  baseBadge: 'base-badge',
  badgeText: 'badge-text',
  wrapper: 'badge-wrapper',
  badgeIcon: 'badge-icon',
};

export const Selectors = {
  wrapper: '.badgeWrapper',
  root: '.root',
  text: '.root::text',
  badgeIcon: '.root::icon',
  innerContainer: '.root::innerContainer',
};

export const translationKeys = {
  componentName: 'dsgnsys_compDisplayName_WutBadge',
  layoutPanel: {
    title: 'dsgnsys_WutBadge_panelTitle_layout',
    sizeSliderLabel: 'dsgnsys_WutBadge_layout_sizeSliderLabel',
  },
  stylablePanel: {
    text: 'StylablePanel_Design_Text_Tab_Title',
    layout: 'StylablePanel_Design_Layout_Tab_Title',
    fill: 'StylablePanel_Design_Borders_Fill_Tooltip',
    background: 'StylablePanel_Design_Fills_Tab_Title',
    corners: 'StylablePanel_Design_Corners_Tab_Title',
    borders: 'StylablePanel_Design_Borders_Tab_Title',
    shadows: 'StylablePanel_Design_Shadows_Tab_Title',
    badgeSkin: 'StylablePanel_Design_Button_Icon_Tab_Label',
    textSection: 'dsgnsys_WutBadge_design_text_dropdown_label',
  },
};
