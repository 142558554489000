
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrSelectableAccordionProps,
  IWsrSelectableAccordionMapperProps,
  IWsrSelectableAccordionControllerProps,
  IWsrSelectableAccordionStateValues,
} from '../../../components/WsrSelectableAccordion/WsrSelectableAccordion.props';

export default withCompController<
  IWsrSelectableAccordionMapperProps,
  IWsrSelectableAccordionControllerProps,
  IWsrSelectableAccordionProps,
  IWsrSelectableAccordionStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
