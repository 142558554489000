import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';

const css = withCompPreviewInfo()(
  ['compLayout', 'componentViewMode'],
  ({ compLayout, componentViewMode }) => {
    const isEditing = componentViewMode === 'editor';
    return {
      width: isEditing ? compLayout.width : 'auto',
      height: isEditing ? compLayout.height : 'auto',
    };
  },
);

export default createComponentPreviewMapperModel({
  css,
});
