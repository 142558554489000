import * as React from 'react';
import {
  InfoIcon,
  Text,
  TextButton,
  WixStyleReactProvider,
} from 'wix-style-react';
import type { IDashboardInfoIconProps } from '../DashboardInfoIcon.props';
import { findKeyByObjectValue, getDataAttributes } from '../../../common/utils';
import { usePlatformLink } from '../../../providers';
import { SIZES } from '../DashboardInfoIcon.constants';
import { classes } from './DashboardInfoIcon.st.css';
import { dashboardInfoIconDataHook } from './constants';

const DashboardInfoIcon: React.FC<IDashboardInfoIconProps> = props => {
  const {
    id,
    className,
    tooltip,
    size,
    addLink,
    tooltipLinkText,
    tooltipLink,
    onTooltipLinkClick,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const linkProps = usePlatformLink(tooltipLink);

  const renderContent = React.useMemo(() => {
    const handleActionClick: React.MouseEventHandler<HTMLElement> = () => {
      onTooltipLinkClick?.({ type: 'tooltipLinkClick' });
    };
    return (
      <div className={classes.tooltipContent}>
        {tooltip && (
          <Text light size="small">
            {tooltip}
          </Text>
        )}
        {addLink && tooltipLinkText && (
          <TextButton
            {...linkProps}
            as={linkProps.href ? 'a' : 'button'}
            skin="light"
            size="small"
            underline="always"
            onClick={handleActionClick}
          >
            {tooltipLinkText}
          </TextButton>
        )}
      </div>
    );
  }, [linkProps, tooltip, addLink, tooltipLinkText, onTooltipLinkClick]);

  const sizeValue =
    findKeyByObjectValue({
      object: SIZES,
      value: size,
    }) || 'medium';

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <InfoIcon
        dataHook={dashboardInfoIconDataHook}
        size={sizeValue}
        content={renderContent}
      />
    </WixStyleReactProvider>
  );
};

export default DashboardInfoIcon;
