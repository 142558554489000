
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuFontAndColorPickerProps,
  IWbuFontAndColorPickerMapperProps,
  IWbuFontAndColorPickerControllerProps,
  IWbuFontAndColorPickerStateValues,
} from '../../../components/WbuFontAndColorPicker/WbuFontAndColorPicker.props';

export default withCompController<
  IWbuFontAndColorPickerMapperProps,
  IWbuFontAndColorPickerControllerProps,
  IWbuFontAndColorPickerProps,
  IWbuFontAndColorPickerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
