import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';

const css = withCompPreviewInfo()(
  ['compLayout', 'componentViewMode'],
  ({ compLayout, componentViewMode }) => {
    const isEditing = componentViewMode === 'editor';
    return {
      '--containerOutline': isEditing ? '1px dashed #868AA5' : 'none',
      '--height': `${compLayout.height}px`,
    };
  },
);

export default createComponentPreviewMapperModel({
  css,
});
