
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuNotificationBannerProps,
  IWbuNotificationBannerMapperProps,
  IWbuNotificationBannerControllerProps,
  IWbuNotificationBannerStateValues,
} from '../../../components/WbuNotificationBanner/WbuNotificationBanner.props';

export default withCompController<
  IWbuNotificationBannerMapperProps,
  IWbuNotificationBannerControllerProps,
  IWbuNotificationBannerProps,
  IWbuNotificationBannerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
