
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrPage3846773723",
      {"classes":{"root":"WsrPage3846773723__root","page":"WsrPage3846773723__page","pageLayout":"WsrPage3846773723__pageLayout","contentContainer":"WsrPage3846773723__contentContainer","actionButtons":"WsrPage3846773723__actionButtons"},"vars":{"containerOutline":"--containerOutline","wix-ads-height":"--wix-ads-height","pageHeight":"--WsrPage3846773723-pageHeight"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  