import * as React from 'react';
import { IconButton, Tooltip, WixStyleReactProvider } from 'wix-style-react';
import {
  ICON_PRIORITIES,
  ICON_SIZES,
  ICON_SKINS,
} from '../DashboardIconButton.constants';
import type { IDashboardIconButtonProps } from '../DashboardIconButton.props';
import { usePlatformIcon, usePlatformLink } from '../../../providers';
import { getDataAttributes, findKeyByObjectValue } from '../../../common/utils';
import { iconButtonHook, iconButtonTooltipHook } from './constants';

const DashboardIconButton: React.FC<IDashboardIconButtonProps> = props => {
  const {
    id,
    className,
    size,
    skin,
    link,
    tooltip,
    priority,
    onClick = () => {},
    onMouseEnter,
    onMouseLeave,
    disabled,
    ...rest
  } = props;

  const linkProps = usePlatformLink(link);
  const icon = usePlatformIcon(rest.icon);

  const sizeValue =
    findKeyByObjectValue({ value: size, object: ICON_SIZES }) || 'medium';

  const priorityValue =
    findKeyByObjectValue({ value: priority, object: ICON_PRIORITIES }) ||
    'primary';

  const skinValue =
    findKeyByObjectValue({ value: skin, object: ICON_SKINS }) || 'standard';

  const renderIconButton = React.useMemo(() => {
    return (
      <IconButton
        disabled={disabled}
        dataHook={iconButtonHook}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        size={sizeValue}
        priority={priorityValue}
        skin={skinValue}
        onClick={onClick}
        {...linkProps}
        ariaLabelledBy={`aria-labelledby-${id}`}
        as={linkProps.href ? 'a' : 'button'}
      >
        {icon}
      </IconButton>
    );
  }, [
    id,
    linkProps,
    skinValue,
    sizeValue,
    icon,
    priorityValue,
    disabled,
    onClick,
    onMouseEnter,
    onMouseLeave,
  ]);

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      className={className}
    >
      {tooltip ? (
        <Tooltip
          dataHook={iconButtonTooltipHook}
          disabled={false}
          content={tooltip}
          aria-labelledby={`aria-labelledby-${id}`}
        >
          {renderIconButton}
        </Tooltip>
      ) : (
        renderIconButton
      )}
    </WixStyleReactProvider>
  );
};

export default DashboardIconButton;
