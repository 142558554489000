
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrSelectableAccordion4120602644",
      {"classes":{"root":"WsrSelectableAccordion4120602644__root","contentContainer":"WsrSelectableAccordion4120602644__contentContainer"},"vars":{"containerOutline":"--containerOutline"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  