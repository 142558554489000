import * as React from 'react';
import { Divider, TextInput } from '@wix/wix-base-ui/lib/esm';
import { getDataAttributes } from '../../../common/utils';

const ControlTypes: React.FC<any> = props => {
  const { id, className } = props;
  return (
    <div id={id} className={className} {...getDataAttributes(props)}>
      <TextInput value="All Control Types" />
      <Divider long />
    </div>
  );
};

export default ControlTypes;
