
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "DashboardHeading2663007774",
      {"classes":{"root":"DashboardHeading2663007774__root","textAlignment":"DashboardHeading2663007774__textAlignment"},"vars":{"width":"--width"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  