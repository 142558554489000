
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WutBadge3555373895",
      {"classes":{"root":"WutBadge3555373895__root","outerWrapper":"WutBadge3555373895__outerWrapper","text":"WutBadge3555373895__text"},"vars":{},"stVars":{"icon-position":"var(--icon-position)","spacing":"var(--spacing)","rootWidth":"var(--root-width)","rootHeight":"var(--root-height)","wrapperWidth":"var(--wrapper-height)","wrapperHeight":"var(--wrapper-height)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  