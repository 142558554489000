export const SIZES = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
} as const;

export const SKINS = {
  standard: 'Standard',
  success: 'Success',
  error: 'Destructive',
} as const;

export const LABEL_POSITIONS = {
  top: 'Top',
  left: 'Left',
  right: 'Right',
} as const;
