
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuColorSelectorProps,
  IWbuColorSelectorMapperProps,
  IWbuColorSelectorControllerProps,
  IWbuColorSelectorStateValues,
} from '../../../components/WbuColorSelector/WbuColorSelector.props';

export default withCompController<
  IWbuColorSelectorMapperProps,
  IWbuColorSelectorControllerProps,
  IWbuColorSelectorProps,
  IWbuColorSelectorStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
