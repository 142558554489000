
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuDrillDownProps,
  IWbuDrillDownMapperProps,
  IWbuDrillDownControllerProps,
  IWbuDrillDownStateValues,
} from '../../../components/WbuDrillDown/WbuDrillDown.props';

export default withCompController<
  IWbuDrillDownMapperProps,
  IWbuDrillDownControllerProps,
  IWbuDrillDownProps,
  IWbuDrillDownStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
