
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "WsrCard2297458969",
      {"classes":{"root":"WsrCard2297458969__root","header":"WsrCard2297458969__header","headerSuffixContainer":"WsrCard2297458969__headerSuffixContainer","contentContainer":"WsrCard2297458969__contentContainer"},"vars":{"containerOutline":"--containerOutline"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  