import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';

const css = withCompPreviewInfo()(
  ['componentViewMode'],
  ({ componentViewMode }) => {
    const isEditing = componentViewMode === 'editor';
    return {
      '--containerOutline': isEditing ? '1px dashed #868AA5' : 'none',
    };
  },
);

const props = withCompPreviewInfo()(
  ['compData', 'componentViewMode'],
  ({ compData, componentViewMode }) => {
    const items = compData.items.map((item: { isDefault: boolean }) => ({
      ...item,
      isOpen: componentViewMode === 'preview' ? undefined : item.isDefault,
      initiallyOpen: item.isDefault,
    }));
    return {
      ...compData,
      items,
    };
  },
);

export default createComponentPreviewMapperModel({
  css,
  props,
});
