
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWsrDividerProps,
  IWsrDividerMapperProps,
  IWsrDividerControllerProps,
  IWsrDividerStateValues,
} from '../../../components/WsrDivider/WsrDivider.props';

export default withCompController<
  IWsrDividerMapperProps,
  IWsrDividerControllerProps,
  IWsrDividerProps,
  IWsrDividerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
