
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuMultilineTextInputProps,
  IWbuMultilineTextInputMapperProps,
  IWbuMultilineTextInputControllerProps,
  IWbuMultilineTextInputStateValues,
} from '../../../components/WbuMultilineTextInput/WbuMultilineTextInput.props';

export default withCompController<
  IWbuMultilineTextInputMapperProps,
  IWbuMultilineTextInputControllerProps,
  IWbuMultilineTextInputProps,
  IWbuMultilineTextInputStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
