import * as React from 'react';
import classnames from 'clsx';
import {
  Composites,
  InfoIcon,
  Button,
  TextLabel,
} from '@wix/wix-base-ui/lib/esm';
import WbuContainer from '../../WbuContainer/WbuContainer';
import type { IWbuButtonProps } from '../WbuButton.props';
import './WbuButton.global.scss';
import { getDataAttributes } from '../../../common/utils';
import { usePlatformLink } from '../../../providers';
import { wbuButtonHook } from './constants';

const WbuButton: React.FC<IWbuButtonProps> = props => {
  const {
    id,
    className,
    label,
    fieldTitle,
    tooltipInfo,
    size,
    skin,
    divider,
    onClick = () => {},
    prefixIcon,
    suffixIcon,
    fullWidth,
    onMouseEnter,
    onMouseLeave,
    isDisabled,
    link,
  } = props;
  const linkProps = usePlatformLink(link);

  const sizes: Record<IWbuButtonProps['size'], string> = {
    small: 'btn-sm',
    medium: 'btn-md',
    large: 'btn-big',
  };

  const skins: Record<IWbuButtonProps['skin'], string> = {
    standard: '', // standard is the default, no class is needed here
    'standard secondary': 'btn-confirm-secondary',
    premium: 'btn-premium-primary',
    'premium secondary': 'btn-premium-secondary',
    alert: 'btn-danger-primary',
    'alert secondary': 'btn-danger-secondary',
    inverted: 'btn-inverted',
  };

  const generateIconElement = (icon: { svgId?: string }) => {
    if (!!icon && icon.svgId) {
      return (
        <div
          className={
            size === 'large' ? 'wbuButton-largeSvg' : 'wbuButton-smallSvg'
          }
          dangerouslySetInnerHTML={{ __html: icon.svgId }}
        />
      );
    }
    return;
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Composites.ButtonLargeLabeled>
        {tooltipInfo && <InfoIcon text={tooltipInfo} />}
        {fieldTitle && <TextLabel value={fieldTitle} />}
        <Button
          dataHook={wbuButtonHook}
          {...linkProps}
          disabled={isDisabled}
          onClick={onClick}
          className={classnames(sizes[size], skins[skin])}
          suffixIcon={generateIconElement(suffixIcon)}
          prefixIcon={generateIconElement(prefixIcon)}
          fullWidth={fullWidth}
        >
          {label}
        </Button>
      </Composites.ButtonLargeLabeled>
    </WbuContainer>
  );
};

export default WbuButton;
