export const AREA_SKINS: { [key: string]: string } = {
  plain: 'Plain',
  filled: 'Filled',
  outlined: 'Outlined',
};

export const SELECTION_AREAS: { [key: string]: string } = {
  always: 'Always',
  hover: 'On mouse hover',
};
