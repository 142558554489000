import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

export const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  const { className, ...restCompData } = compData;
  return {
    ...{ required: false, checked: false, radioGroupName: '' },
    stylableClassName: className,
    ...restCompData,
  };
});

const css = withCompInfo()([], () => {
  return {
    width: 'auto',
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
