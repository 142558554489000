import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { SizeTypes } from '../DashboardText.constants';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...compData,
  };
});

const css = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  const { size } = compData;

  const minHeights: { [K in SizeTypes]: string } = {
    Medium: '24px',
    Small: '18px',
    Tiny: '18px',
  };
  const minHeight = minHeights[size as SizeTypes];

  return {
    'min-height': minHeight,
    height: 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
