
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuRichTextProps,
  IWbuRichTextMapperProps,
  IWbuRichTextControllerProps,
  IWbuRichTextStateValues,
} from '../../../components/WbuRichText/WbuRichText.props';

export default withCompController<
  IWbuRichTextMapperProps,
  IWbuRichTextControllerProps,
  IWbuRichTextProps,
  IWbuRichTextStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
