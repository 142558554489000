
import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';


const props = withCompInfo<any, any>()(
  ['compData', 'compStylableClass'],
  ({compData, compStylableClass}) => {
      
    return {
      ...{ items: [],
activeTabIndex: 0,
 },
...compData,
stylableClassName: compStylableClass,

    };
  },
);




export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
