
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "usePlatformImage669212235",
      {"classes":{"root":"usePlatformImage669212235__root"},"vars":{"width":"--usePlatformImage669212235-width","height":"--usePlatformImage669212235-height"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  