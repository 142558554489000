export const STATUSES: { [key: string]: string } = {
  none: 'Default',
  error: 'Error',
  warning: 'Warning',
  loading: 'Loading',
};

export const ACTIVE_STATES: {
  [key: string]: {
    value: string;
    readOnly: boolean;
    disabled: boolean;
  };
} = {
  ACTIVE: {
    value: 'Active',
    readOnly: false,
    disabled: false,
  },
  READ_ONLY: {
    value: 'Read only',
    readOnly: true,
    disabled: false,
  },
  DISABLED: {
    value: 'Disabled',
    readOnly: false,
    disabled: true,
  },
};

export const BORDERS: { [key: string]: string } = {
  standard: 'Standard',
  round: 'Round',
  bottomLine: 'Underline only',
  none: 'None',
};
