import React from 'react';
import { classes } from './usePlatformIcon.st.css';

/** A hook to return platform icon expressed as React component with color inheritance */
const usePlatformIcon = (props: { svgId: string }) => {
  return props?.svgId ? (
    <div
      className={classes.root}
      dangerouslySetInnerHTML={{ __html: props.svgId }}
    />
  ) : undefined;
};

export default usePlatformIcon;
