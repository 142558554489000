
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IWbuToggleSwitchProps,
  IWbuToggleSwitchMapperProps,
  IWbuToggleSwitchControllerProps,
  IWbuToggleSwitchStateValues,
} from '../../../components/WbuToggleSwitch/WbuToggleSwitch.props';

export default withCompController<
  IWbuToggleSwitchMapperProps,
  IWbuToggleSwitchControllerProps,
  IWbuToggleSwitchProps,
  IWbuToggleSwitchStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
