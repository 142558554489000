import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { defaultAvatars, Selectors } from '../constants';
import type { IWutAvatarGroupProps } from '../WutAvatarGroup.props';
import { props as viewerPropsMapper } from './WutAvatarGroup.mapper';

const textAvatar = { firstName: 'ABC', textType: 'plain' };
const anonymousIconAvatar = {};

const generateAvatarsArray = (length: number, avatar: object) =>
  Array.from({ length }).map(() => avatar);

const avatarsStylableStates = {
  [Selectors.avatar]: (length: number) =>
    generateAvatarsArray(length, anonymousIconAvatar),
  [Selectors.avatarContent]: (length: number) =>
    generateAvatarsArray(length, textAvatar),
};

const props = composeMapper<IWutAvatarGroupProps, any, any>()(
  // the component mapper
  viewerPropsMapper,
  // additional dependencies to inject to the preview mapper function
  { add: ['compPreviewState'] },
  // preview mapper function
  (resolver, deps, carmiData) => {
    // calculate the props of the mapper
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;

    if (compPreviewState) {
      const avatarsLength = viewerProps.avatars.length || defaultAvatars.length;
      return {
        ...viewerProps,
        avatars: avatarsStylableStates[compPreviewState](avatarsLength) || [],
        maxAvatars:
          viewerProps.maxAvatars + (viewerProps.showHiddenAvatarCount ? 1 : 0),
        showHiddenAvatarCount: false,
      };
    }

    return viewerProps;
  },
);

export default createComponentPreviewMapperModel({ props });
