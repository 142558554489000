import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { Selectors } from '../constants';
import type { IWutAvatarProps } from '../WutAvatar.props';
import { props as viewerPropsMapper } from './WutAvatar.mapper';

const textAvatar = {
  firstName: 'ABC',
  textType: 'plain',
};
const anonymousAvatar = {
  firstName: '',
  lastName: '',
  src: '',
};

const props = composeMapper<IWutAvatarProps, any, any>()(
  // the component mapper
  viewerPropsMapper,
  // additional dependencies to inject to the preview mapper function
  { add: ['compPreviewState'] },
  // preview mapper function
  (resolver, deps, carmiData) => {
    // calculate the props of the mapper
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;

    if (!compPreviewState) {
      return viewerProps;
    }

    if (compPreviewState === Selectors.root) {
      return {
        ...viewerProps,
        ...anonymousAvatar,
      };
    }

    return {
      ...viewerProps,
      ...textAvatar,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
