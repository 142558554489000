import * as React from 'react';
import { Button, Loader, WixStyleReactProvider } from 'wix-style-react';
import type { IDashboardButtonProps } from '../DashboardButton.props';
import { usePlatformIcon, usePlatformLink } from '../../../providers';
import { getDataAttributes, findKeyByObjectValue } from '../../../common/utils';
import { PRIORITIES, SIZES, SKINS } from '../DashboardButton.constants';
import { dashboardButtonHook } from './constants';

const DashboardButton: React.FC<IDashboardButtonProps> = props => {
  const {
    id,
    className,
    label,
    showPrefix,
    prefixIcon,
    showSuffix,
    suffixIcon,
    onMouseEnter,
    onMouseLeave,
    disabled,
    loading,
    link,
    onClick = () => {},
    onDblClick,
    ...rest
  } = props;

  const platformPrefixIcon = usePlatformIcon(prefixIcon);
  const platformSuffixIcon = usePlatformIcon(suffixIcon);
  const linkProps = usePlatformLink(link);

  const prefix = showPrefix ? platformPrefixIcon : undefined;
  const suffix = showSuffix ? platformSuffixIcon : undefined;

  const size = findKeyByObjectValue({ object: SIZES, value: rest.size });
  const skin = findKeyByObjectValue({ object: SKINS, value: rest.skin });
  const priority = findKeyByObjectValue({
    object: PRIORITIES,
    value: rest.priority,
  });

  return (
    <WixStyleReactProvider
      id={id}
      features={{ newColorsBranding: true }}
      {...getDataAttributes(props)}
      className={className}
    >
      <Button
        {...linkProps}
        as={linkProps.href ? 'a' : undefined}
        dataHook={dashboardButtonHook}
        skin={skin}
        disabled={disabled}
        size={size}
        priority={priority}
        onClick={onClick}
        onDoubleClick={onDblClick}
        prefixIcon={prefix}
        suffixIcon={suffix}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {loading ? <Loader size="tiny" /> : label}
      </Button>
    </WixStyleReactProvider>
  );
};

export default DashboardButton;
