import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { IWutAvatarProps } from '../WutAvatar.props';

export const props = withCompInfo<IWutAvatarProps, any>()(
  ['compData', 'compStylableClass'],
  ({ compData, compStylableClass }) => {
    return {
      ...{ firstName: '', lastName: '', src: '' },
      ...compData,
      stylableClassName: compStylableClass,
    };
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
