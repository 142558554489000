import * as React from 'react';
import { Composites, BackButton } from '@wix/wix-base-ui/lib/esm';
import type { IWbuBackButtonProps } from '../WbuBackButton.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuBackButton.global.scss';
import { getDataAttributes } from '../../../common/utils';

const WbuBackButton: React.FC<IWbuBackButtonProps> = props => {
  const { id, className, label, onClick = () => {} } = props;

  return (
    <WbuContainer id={id} {...getDataAttributes(props)} className={className}>
      <Composites.ButtonLeft>
        <BackButton label={label} onClick={onClick} />
      </Composites.ButtonLeft>
    </WbuContainer>
  );
};

export default WbuBackButton;
