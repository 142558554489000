import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  return {
    ...compData,
    link: compData.link?.resolvedLink,
  };
});

const css = withCompInfo()(['compData'], ({ compData }) => {
  return {
    height: 'auto',
    width: compData.loading ? undefined : 'auto',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
