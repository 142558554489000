
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IDashboardInputProps,
  IDashboardInputMapperProps,
  IDashboardInputControllerProps,
  IDashboardInputStateValues,
} from '../../../components/DashboardInput/DashboardInput.props';

export default withCompController<
  IDashboardInputMapperProps,
  IDashboardInputControllerProps,
  IDashboardInputProps,
  IDashboardInputStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
