import * as React from 'react';
import { TextButton, Composites } from '@wix/wix-base-ui/lib/esm';
import type { IWbuLeftTextButtonProps } from '../WbuLeftTextButton.props';
import WbuContainer from '../../WbuContainer/WbuContainer';
import './WbuLeftTextButton.global.scss';
import { getDataAttributes } from '../../../common/utils';

const LeftTextButton: React.FC<IWbuLeftTextButtonProps> = props => {
  const {
    id,
    className,
    text,
    size,
    skin,
    weight,
    url,
    prefixIcon,
    suffixIcon,
    divider,
  } = props;

  const generateIconElement = (icon: { svgId?: string }) => {
    if (!!icon && icon.svgId) {
      return (
        <div
          className={
            size === 'medium'
              ? 'wbuLeftTextButton-mediumSvg'
              : 'wbuLeftTextButton-smallSvg'
          }
          dangerouslySetInnerHTML={{ __html: icon.svgId }}
        />
      );
    }
    return;
  };

  return (
    <WbuContainer
      id={id}
      {...getDataAttributes(props)}
      className={className}
      divider={divider}
    >
      <Composites.ButtonLeft>
        <TextButton
          size={size}
          skin={skin}
          weight={weight}
          onClick={() => window.open(url)}
          prefixIcon={generateIconElement(prefixIcon)}
          suffixIcon={generateIconElement(suffixIcon)}
        >
          {text}
        </TextButton>
      </Composites.ButtonLeft>
    </WbuContainer>
  );
};

export default LeftTextButton;
